import FormLabel from "@mui/material/FormLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { blue } from "@mui/material/colors";
import { ENVIRONMENT } from "../../../constants";
import { Provider } from "../../../interfaces/interfaces";

type Props = {
  environment: string;
  setEnvironment: (environment: string) => void;
  setProvider: (setProvider: Provider) => void;
  setOperatorUrl: (operatorUrl: string) => void;
};

export function EnvironmentField({
  environment,
  setEnvironment,
  setProvider,
  setOperatorUrl,
}: Props) {
  const handleEnvironment = (e: SelectChangeEvent<string>) => {
    setEnvironment(e.target.value);
    setProvider({
      name: "",
      base_urls: [],
    });
    setOperatorUrl("");
  };

  return (
    <>
      <FormLabel>Environment</FormLabel>
      <Select
        value={environment}
        name="env"
        onChange={(e) => handleEnvironment(e)}
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        {ENVIRONMENT.map((env) => (
          <MenuItem key={env} value={env}>
            {env}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
