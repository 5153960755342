import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { Game, Params, StringIndex } from "../../interfaces/interfaces";
import ProviderFields from "../ProviderFields/ProviderFields";
import DefaultFields from "../DefaultFields/DefaultFields";

type Props = {
  setUrlForBranch: (url: string) => void;
  urlForBranch: string;
  isBranchTest: boolean;
  setBranchTest: (isBranchTest: boolean) => void;
  isProviderTest: boolean;
  setProviderTest: (isProviderTest: boolean) => void;
  params: Params;
  setParams: (params: Params) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
  providerInputData: StringIndex;
  setProviderInputData: (providerInputData: StringIndex) => void;
  operatorUrl: string;
  setOperatorUrl: (operatorUrl: string) => void;
  ishomeURLValid: boolean;
  iscashierURLValid: boolean;
  setIshomeURLValid: (ishomeURLValid: boolean) => void;
  setIscashierURLValid: (iscashierURLValid: boolean) => void;
};

function FormLauncher({
  urlForBranch,
  setUrlForBranch,
  isBranchTest,
  setBranchTest,
  isProviderTest,
  setProviderTest,
  params,
  setParams,
  slotGamesSorted,
  videoBingoGamesSorted,
  providerInputData,
  setProviderInputData,
  operatorUrl,
  setOperatorUrl,
  ishomeURLValid,
  iscashierURLValid,
  setIshomeURLValid,
  setIscashierURLValid,
}: Props) {
  const { features } = useConfigProvider();

  return (
    <>
      {features.withProvider && (
        <>
          <div>
            <FormLabel>Test por proveedor
            <Checkbox
              checked={isProviderTest}
              name="testprovider"
              onChange={(e) => {
                setProviderTest(e.target.checked);
              }}
              sx={{
                color: blue[800],
                "&.Mui-checked": {
                  color: blue[600],
                },
              }}
            ></Checkbox></FormLabel>
          </div>
        </>
      )}
      {isProviderTest ? (
        <ProviderFields
          providerInputData={providerInputData}
          setProviderInputData={setProviderInputData}
          operatorUrl={operatorUrl}
          setOperatorUrl={setOperatorUrl}
          slotGamesSorted={slotGamesSorted}
          videoBingoGamesSorted={videoBingoGamesSorted}
          ishomeURLValid={ishomeURLValid}
          iscashierURLValid={iscashierURLValid}
          setIshomeURLValid={setIshomeURLValid}
          setIscashierURLValid={setIscashierURLValid}
        />
      ) : (
        <DefaultFields
          urlForBranch={urlForBranch}
          setUrlForBranch={setUrlForBranch}
          isBranchTest={isBranchTest}
          setBranchTest={setBranchTest}
          params={params}
          setParams={setParams}
          slotGamesSorted={slotGamesSorted}
          videoBingoGamesSorted={videoBingoGamesSorted}
        />
      )}
    </>
  );
}

export default FormLauncher;
