import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function DemoCheckbox({ params, handleChange }: Props) {
  return (
    <div>
      <FormLabel>
        Demo
        <Checkbox
          checked={Boolean(+params.demo)}
          name="demo"
          onChange={(e) => {
            handleChange("demo", e.target.checked);
          }}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        ></Checkbox>
      </FormLabel>
    </div>
  );
}
