import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { blue } from "@mui/material/colors";
import { Provider } from "../../../interfaces/interfaces";

type Props = {
  provider: Provider;
  operatorUrl: string;
  setOperatorUrl: (operatorUrl: string) => void;
};

export function OperatorURLField({
  provider,
  operatorUrl,
  setOperatorUrl,
}: Props) {
  return (
    <>
      <FormLabel>Operator URL</FormLabel>
      {provider.name && (
        <Select
          value={operatorUrl}
          name="operatorUrl"
          onChange={(e) => {
            setOperatorUrl(e.target.value);
          }}
          sx={{
            "& fieldset": {
              borderColor: blue[800],
            },
            "&:hover": {
              "&& fieldset": {
                border: "1px solid white",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          }}
        >
          {provider.base_urls.map((item: string) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}
