import FormLabel from "@mui/material/FormLabel";
import { StringIndex } from "../../../interfaces/interfaces";
import Radio from "@mui/material/Radio";
import { blue } from "@mui/material/colors";

type Props = {
  entrie: string[];
  providerInputData: StringIndex;
  handleInputsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function HideFullScreenHandRadio({
  entrie,
  providerInputData,
  handleInputsChange,
}: Props) {
  return (
    <div>
      <FormLabel>{entrie[0]}</FormLabel>
      <Radio
        name={entrie[1]}
        value="true"
        id="true"
        checked={providerInputData?.[`${entrie[1]}`] === "true"}
        onChange={handleInputsChange}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>true</span>
      <Radio
        name={entrie[1]}
        value="false"
        id="false"
        checked={providerInputData?.[`${entrie[1]}`] === "false"}
        onChange={handleInputsChange}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>false</span>
    </div>
  );
}
