import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";

type Props = {
  setUrlForBranch: (isBranchTest: string) => void;
  urlForBranch: string;
};

export function BranchUrlTextField({
  urlForBranch,
  setUrlForBranch,
}: Props) {
  return (
    <>
      <FormLabel>URL</FormLabel>
      <TextField
        type="text"
        name="url"
        value={urlForBranch}
        onChange={(e) => {
          setUrlForBranch(e.target.value);
        }}
        spellCheck="false"
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
        }}
      />
    </>
  );
}
