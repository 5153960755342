import { create } from "zustand";

type Params = {
  [key: string]: any;
};

type State = {
  params: Params;
};

type Actions = {
  setParams: (params: Params) => void;
};

export const useGameParamsStore = create<State & Actions>((set) => ({
  params: {
    currency: "EUR",
    isMobile: true
  },
  setParams: (params) => set((state) => (
    { ...state.params, params}
  )),
}));
