import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import api3 from "../api/api3";
import { isDevelopment } from "../utils";
import { DataConfigVersion } from "../interfaces/interfaces";

const path = isDevelopment() ? "versionData" : "configurations/version.json";

async function fetchVersionData(ctx: QueryFunctionContext) {
  const res = await api3.get<DataConfigVersion>(`/${path}`);
  return res.data.data || res.data;
}

export function useConfigurationVersion() {
  return useQuery({
    queryKey: ["versionData"],
    queryFn: fetchVersionData,
    keepPreviousData: false,
    cacheTime: 0,
  });
}
