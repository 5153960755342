import "./Login.scss";
import logo from "../../assets/zitro-digital.png";
import Particles from "react-tsparticles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import particlesConfig from "../../config/particles.json";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAuthStore } from "../../store/auth";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { WITH_GUEST } from "../../constants";
import { useLogin } from "../../hooks/useLogin";
import { useState, useRef } from "react";
import LegalAgeModal from "../LegalAgeModal/LegalAgeModal";

type Props = {
  loginUrl: string;
};

export default function Login ({loginUrl}:Props) {
  const setToken = useAuthStore((state) => state.setToken);
  const [isShowLAModal, setShowLAModal] = useState(false);
  const {features} = useConfigProvider();
  const login = useLogin(loginUrl);
  const myForm = useRef<any>(null);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submitter = myForm.current.buttonId;
    if (submitter !== "login") return;
      const username = (e.currentTarget.elements[0] as HTMLInputElement).value;
      const password = (e.currentTarget.elements[1] as HTMLInputElement).value;
      try {
        const token = await login.mutateAsync({
          credentials: { username, password },
        });
        setToken(token);
      } catch {
        throw new Error(login.error as string);
      }
  };

  const loginAsGuest = (key: string) => {
    const token = (Object.keys(features) as (keyof typeof features)[])
      .find((k) => k === key)
      ?.toLowerCase()!;
    setToken(token);
  };

  const showLAModal = () => {
    setShowLAModal(true);
  };

  const hideLAModal = () => {
    setShowLAModal(false);
  };

  return (
    <div className="login">
      {<Particles params={particlesConfig as any} />}
      <div className="login-form">
        <div className="logo">
          <img src={logo} className="logo" alt="zitro digital" />
        </div>
        <form onSubmit={handleSubmit} ref={myForm}>
          <label>
            <FontAwesomeIcon icon={faUser as IconProp} />
            <input type="text" placeholder="Username" autoComplete="username" />
          </label>
          <label>
            <FontAwesomeIcon icon={faKey as IconProp} />
            <input
              type="password"
              placeholder="Password"
              autoComplete="current-password"
            />
          </label>
          <div className="login-btns">
            <button id="login" onClick={(e) => (myForm.current.buttonId = (e.target as Element).id)} type="submit">
              Login
            </button>
            {features.withGuest && (
              <button
                id="guest"
                onClick={() =>
                  features.withLegalAgeModal
                    ? showLAModal()
                    : loginAsGuest(WITH_GUEST)
                }
              >
                Guest
              </button>
            )}
          </div>
        </form>
        {login.isError && (
          <div className="login-error">Incorrect username or password</div>
        )}
      </div>
      {isShowLAModal && (
        <LegalAgeModal
          showMLA={isShowLAModal}
          onCloseMLA={hideLAModal}
          loginAsGuest={loginAsGuest}
        />
      )}
    </div>
  );
};

