import React from "react";
import "./Dashboard.scss";
import { useAuthStore } from "../../store/auth";
import Carousel from "../../components/Carousel/Carousel";
import Preview from "../../components/Preview/Preview";
import { Game } from '../../interfaces/interfaces';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FloatingButtons from "../../components/FloatingButtons/FloatingButtons";
import ConfigGameBar from "../../components/ConfigGameBar/ConfigGameBar";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { useJackpots } from "../../hooks/useJackpots";
import { changeVBgameNames } from "../../utils";
import { PROVIDER_OPERATOR_ID, CURRENCY } from "../../constants"
import { useConfigurationVersion } from "../../hooks/useConfigurationVersion";

type Props = {
  games: Game[];
  filtering: boolean;
  gameUrl: string;
  baseUrl: string;
  configUrl: string;
  defaultGameLanguage: string;
};

const Dashboard = ({ games, filtering, gameUrl, baseUrl, configUrl, defaultGameLanguage }: Props) => {
  const token = useAuthStore((state) => state.token);
  const demo = token ? token === "withguest" : false;
  const { features } = useConfigProvider();
  const {data} = useJackpots({baseUrl, providerOperatorId: PROVIDER_OPERATOR_ID, currency: CURRENCY, gameName: ""});
  const jackpots = data?.filter(jackpot => jackpot.type === 'MEGA' || jackpot.type === 'BASE30');
  const {data: configurationVersion} = useConfigurationVersion();

  return (
    <div className="dashboard">
      <Header searchIsVisible={true} />
      {!filtering && (
        <div className="dashboard-main-carousel">
          <Carousel token={token} demo={demo} gameUrl={gameUrl} />
        </div>
      )}
      <FloatingButtons token={token} />
      {features.withConfigGameBar && <ConfigGameBar defaultGameLanguage={defaultGameLanguage}/>}
      <div className="dashboard-games">
        <div className={ features.withJackpots ?  "dashboard-games-list-jackpot" : "dashboard-games-list"}>
          {games.map((game) => ( configurationVersion &&
            <Preview
              game={game}
              key={game.name}
              token={token}
              demo={demo}
              gameUrl={gameUrl}
              configUrl={configUrl}
              jackpot= { jackpots?.find(jackpot => {
                if(jackpot.type === 'BASE30') {
                  const jackpotGames = jackpot.games.map(game => changeVBgameNames(game));
                  return jackpotGames.includes(game.name)
                }
                return jackpot.games.includes(game.name)})! }
              configurationVersion={configurationVersion}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default React.memo(Dashboard);
