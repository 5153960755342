import { ReactNode, createContext, useContext } from "react";
import { Data } from '../interfaces/interfaces';
import Spinner from "../components/Spinner/Spinner";
import { useData } from "../hooks/useData";

type Props = {
  children?: React.ReactNode;
};

export const configContext = createContext<Data>({} as Data);

export const ConfigDataProvider: React.FC<Props> = ({ children }) => {

  const { data, isLoading, isError, error } = useData();
  if(!data) (<Spinner/>)

  return (
    <configContext.Provider value={data ?? {} as Data}>
      {isLoading ? <Spinner /> : children}
      { isError && <div>Error! {error as ReactNode} </div>
      }
    </configContext.Provider>
  );
};

export const useConfigProvider = () => useContext<Data>(configContext);
