import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function HideFullScrenHandRadio({
  params,
  handleChange,
}: Props) {
  return (
    <div>
      <FormLabel className="label-padding">Hide fullscreen hand</FormLabel>
      <Radio
        name="hideFullScreenHand"
        value="true"
        id="true"
        checked={params.hideFullScreenHand === "true"}
        onChange={(e) => {
          handleChange("hideFullScreenHand", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>true</span>
      <Radio
        name="hideFullScreenHand"
        value="false"
        id="false"
        checked={params.hideFullScreenHand === "false"}
        onChange={(e) => {
          handleChange("hideFullScreenHand", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>false</span>
    </div>
  );
}
