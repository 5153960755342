import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { EQUIVALENT_KEYS } from "../../../constants";
import { StringIndex } from "../../../interfaces/interfaces";

type Props = {
  entrie: string[];
  providerInputData: StringIndex;
  handleInputsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function DemoCheckbox({
  entrie,
  providerInputData,
  handleInputsChange,
}: Props) {
  return (
    <React.Fragment>
      {EQUIVALENT_KEYS.demo.includes(entrie[1]) ? (
        <div>
          <FormLabel>
            {entrie[0]}
            <Checkbox
              checked={Boolean(
                providerInputData?.[`${entrie[1]}`] === "false" ? 0 : 1
              )}
              name={entrie[1]}
              onChange={handleInputsChange}
              sx={{
                color: blue[800],
                "&.Mui-checked": {
                  color: blue[600],
                },
              }}
            ></Checkbox>
          </FormLabel>
        </div>
      ) : (
        <React.Fragment>
          <FormLabel>{entrie[0]}</FormLabel>
          <TextField
            placeholder={`${entrie[0]}`}
            name={entrie[1]}
            onChange={handleInputsChange}
            value={
              providerInputData?.[`${entrie[1]}`] === undefined
                ? ""
                : providerInputData?.[`${entrie[1]}`]
            }
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
