import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import { blue } from "@mui/material/colors";

type Config = {
  [key: string]: boolean;
};

type Props = {
  config: Config;
  handleConfigChange: (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
};

export function ReloadOnChangeCheckbox({
  config,
  handleConfigChange,
}: Props) {
  return (
    <div>
      <FormLabel>
        Reload on change screen
        <Checkbox
          checked={config.iFrameReload}
          name="iFrameReload"
          onChange={handleConfigChange}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        ></Checkbox>
      </FormLabel>
    </div>
  );
}
