import React from "react";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { StringIndex } from "../../../interfaces/interfaces";

type Props = {
  entrie: string[];
  providerInputData: StringIndex;
  handleInputsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function DefaultField({
  entrie,
  providerInputData,
  handleInputsChange,
}: Props) {
  return (
    <React.Fragment>
      <FormLabel>{entrie[0]}</FormLabel>
      <TextField
        placeholder={`${entrie[0]}`}
        name={entrie[1]}
        onChange={handleInputsChange}
        value={
          providerInputData?.[`${entrie[1]}`] === undefined
            ? ""
            : providerInputData?.[`${entrie[1]}`]
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
        }}
      />
    </React.Fragment>
  );
}
