import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { LANGUAGES } from "../../../constants";
import { blue } from "@mui/material/colors";
import React from "react";
import { StringIndex } from "../../../interfaces/interfaces";
import { SelectChangeEvent } from "@mui/material/Select";

type Props = {
  entrie: string[];
  handleTypeSelect: (e: SelectChangeEvent<string>) => void;
  providerInputData: StringIndex;
};

export function LanguageSelect({
  entrie,
  handleTypeSelect,
  providerInputData,
}: Props) {
  return (
    <React.Fragment>
      <FormLabel>{entrie[0]}</FormLabel>
      <Select
        name={entrie[1]}
        onChange={handleTypeSelect}
        value={
          providerInputData?.[`${entrie[1]}`] === undefined
            ? ""
            : providerInputData?.[`${entrie[1]}`]
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        {Object.entries(LANGUAGES).map((lang) => (
          <MenuItem key={lang[0]} value={lang[1]}>
            {lang[0]}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}
