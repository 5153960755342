import Autocomplete from "@mui/material/Autocomplete";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { Game, Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  isBranchTest: boolean;
  handleChangeGame: ( value: string, name: string) => void;
  gameType: string;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
};

export function GameSelect({
  params,
  isBranchTest,
  handleChangeGame,
  gameType,
  slotGamesSorted,
  videoBingoGamesSorted,
}: Props) {
  return (
    <>
      <FormLabel>Game</FormLabel>
      <Autocomplete
        value={params.name}
        onChange={(e, value): void => {
          handleChangeGame(value || "", "name");
        }}
        disablePortal
        options={
          gameType === "slot"
            ? slotGamesSorted
                .map(({ name, type }) => {
                  if (isBranchTest) {
                    if (type === "cc") {
                      return name;
                    } else {
                      return null;
                    }
                  } else {
                    return name;
                  }
                })
                .filter((games) => games !== null)
            : videoBingoGamesSorted.map(({ name }) => name)
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: "black",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: "black",
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
            {
              color: "black",
            },
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
