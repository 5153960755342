import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { blue } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { StringIndex, Game } from "../../../interfaces/interfaces";

type Props = {
  entrie: string[];
  providerInputData: StringIndex;
  gameType: string;
  handleChangeGame: (
    value: string,
    entrie: string
  ) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
};

export function GameAutocomplete({
  entrie,
  providerInputData,
  gameType,
  handleChangeGame,
  slotGamesSorted,
  videoBingoGamesSorted,
}: Props) {
  return (
    <React.Fragment>
      <FormLabel>{entrie[0]}</FormLabel>
      <Autocomplete
        value={
          providerInputData?.[`${entrie[1]}`] === undefined
            ? ""
            : providerInputData?.[`${entrie[1]}`]
        }
        onChange={(e, value): void => {
          handleChangeGame(value || "", entrie[1]);
        }}
        disablePortal
        options={
          gameType === "slot"
            ? slotGamesSorted.map(({ name }) => name)
            : videoBingoGamesSorted.map(({ name }) => name)
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: "black",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: "black",
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
            {
              color: "black",
            },
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </React.Fragment>
  );
}
