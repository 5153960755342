import React from "react";
import "./Header.scss";
import logo from "../../assets/zitro-digital.png";
import slot from "../../assets/Slots-Icon.png";
import vb from "../../assets/videobingo-icon.png";
import tournament from "../../assets/tournament-icon.png";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useSearchStore } from "../../store/search";
import { useConfigProvider } from "../../contexts/ConfigProvider";

type Props = {
  searchIsVisible: boolean;
};

const Header = ({ searchIsVisible }: Props) => {
  let locationUrl = useLocation();
  let history = useHistory();
  const setSearch = useSearchStore((state) => state.setSearch);
  const { features } = useConfigProvider();

  const onClickSlotLogo = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push("/slots");
  };

  const onClickVBLogo = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    history.push("/vb");
  };

  const onClickTournamentLogo = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    history.push("/tournament");
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-logo">
          <img src={logo} className="logo" alt="zitro digital" />
        </div>

        <div className="header-links">
          <div
            className={`header-links-item ${
              locationUrl.pathname === "/slots" ? "active-item" : ""
            }`}
            onClick={onClickSlotLogo}
          >
            <img src={slot} className="logo" alt="zitro digital" />
            <Link to="/slots">SLOTS</Link>
          </div>
          <div
            className={`header-links-item ${
              locationUrl.pathname === "/vb" ? "active-item" : ""
            }`}
            onClick={onClickVBLogo}
          >
            <img src={vb} className="logo" alt="zitro digital" />
            <Link to="/vb">VIDEOBINGO</Link>
          </div>
          {features.withTournamentPage && (
          <div
            className={`header-links-item ${
              locationUrl.pathname === "/tournament" ? "active-item" : ""
            }`}
            onClick={onClickTournamentLogo}
          >
            <img src={tournament} className="logo" alt="zitro digital" />
            <Link to="/tournament">TOURNAMENT</Link>
          </div>
          )}
        </div>

        <div className={`header-search ${!searchIsVisible ? "header-search-hide" : ""}`}>
          <FontAwesomeIcon icon={faSearch as IconProp} />
          <input
            type="text"
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setSearch(e.target.value)
            }
          />
        </div>
      </div>
    </header>
  );
};
export default React.memo(Header);
