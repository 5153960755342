import "./Launcher.scss";
import { useState, useEffect } from "react";
import { useAuthStore } from "../store/auth";
import { GAME_DEFAULT_SLOTS, GAME_DEFAULT_VB, URL_FOR_BRANCH } from "../constants";
import { useLauncherStore } from "../store/launcher";
import IframeModal from "../components/IframeModal/IframeModal";
import HomeButton from "../components/HomeButton/HomeButton";
import FormLauncher from "../components/FormLauncher/FormLauncher";
import { StringIndex, Game, Slots, Vb, Config } from '../interfaces/interfaces';
import { EQUIVALENT_KEYS } from "../constants/index";
import { blue } from "@mui/material/colors";
import CopyUrl from "../components/CopyUrl/CopyUrl";

interface Props {
  slots: Slots;
  vb: Vb;
  config: Config
}

export default function Launcher({slots, vb, config}:Props) {
  const [isProviderTest, setProviderTest] = useState(false);
  const [isBranchTest, setBranchTest] = useState(false);
  const [urlForBranch, setUrlForBranch] = useState(
    URL_FOR_BRANCH
  );
  const token = useAuthStore((state) => state.token);
  const { gameType, config: configIframe, iFrameCompatibility, setIFrameCompatibility } =
    useLauncherStore((state) => state);

  const [slotGamesSorted, setSlotGamesSorted] = useState<Game[]>(
    slots.games.sort((a, b) => a.name.localeCompare(b.name)) || []
  );
  const [videoBingoGamesSorted, setVideoBingoGamesSorted] = useState<Game[]>(
    vb.games.sort((a, b) => a.name.localeCompare(b.name)) || []
  );

  const [params, setParams] = useState({
    branch: "ZD-",
    name:
      gameType === "slot"
        ? slotGamesSorted[0].name
        : videoBingoGamesSorted[0].name,
    demo: false,
    device: "mobile",
    lang: "es",
    hideFullScreenHand: "true",
    cashierURL: "https://www.google.com/search?q=abc&num=3",
    homeURL: "https://www.google.com/search?q=abc&num=3",
    providerId: "zitro",
    providerOperatorId: "showroom",
  });

  const [isShowModal, setShowModal] = useState(false);
  const [gameURL, setGameURL] = useState("");
  const [providerURL, setProviderURL] = useState("");
  const [operatorUrl, setOperatorUrl] = useState("");

  const [ishomeURLValid, setIshomeURLValid] = useState<boolean>(true);
  const [iscashierURLValid, setIscashierURLValid] = useState<boolean>(true);
  const [providerInputData, setProviderInputData] = useState<StringIndex>({});

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setParams((prev) => {
      return {
        ...prev,
        name: gameType === "slot" ? GAME_DEFAULT_SLOTS : GAME_DEFAULT_VB,
      };
    });
  }, [gameType]);

  useEffect(() => {
    if (window.screen.width <= 1024) {
      setIFrameCompatibility(false);
    }
  }, [setIFrameCompatibility]);

  useEffect(() => {
    return () => {
      setSlotGamesSorted([]);
      setVideoBingoGamesSorted([]);
    };
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let url;
    const encodeCashierURL = params.cashierURL.includes("%") ? params.cashierURL : (`${encodeURIComponent(params.cashierURL)}`);
    const encodeHomeURL = params.homeURL.includes("%") ? params.homeURL : (`${encodeURIComponent(params.homeURL)}`);

    if (isBranchTest) {
      url = `${urlForBranch}/${params.branch}/link_88/${
        params.name
      }/index.html?game=${
        params.name
      }&token=${token}&demo=${+params.demo}&lang=${
        params.lang
      }&hideFullScreenHand=${params.hideFullScreenHand}&cashierURL=${
        encodeCashierURL
      }&homeURL=${encodeHomeURL}&providerId=${
        params.providerId
      }&providerOperatorId=${params.providerOperatorId}&device=${
        params.device
      }`;
    } else {
      url = `${config.game_url}?game=${
        params.name
      }&token=${token}&demo=${+params.demo}&lang=${
        params.lang
      }&hideFullScreenHand=${params.hideFullScreenHand}&cashierURL=${
        encodeCashierURL
      }&homeURL=${encodeHomeURL}&device=${params.device}`;
    }

    setGameURL(url);
    if (configIframe.iFrameMode) {
      showModal();
    } else {
      iFrameCompatibility
        ? window.open(url)
        : (window.location.href = `${url}`);
    }
  };

  const handleProviderSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let url = `https://${operatorUrl}/?`;

    for (const [key, value] of Object.entries(providerInputData)) {
      if (EQUIVALENT_KEYS.demo.includes(`${key}`)) {
        value === "true" ? (url += `${key}=1&`) : (url += `${key}=0&`);
      } else if (
        (EQUIVALENT_KEYS.cashierURL.includes(`${key}`) ||
          EQUIVALENT_KEYS.homeURL.includes(`${key}`)) &&
        value !== ""
      ) {
        value.includes("%")
          ? (url += `${key}=${value}&`)
          : (url += `${key}=${encodeURIComponent(value)}&`);
      } else if (value !== "") {
        url += `${key}=${value}&`;
      }
    }
    url = url.slice(0, url.length - 1);

    if (ishomeURLValid && iscashierURLValid) {
      setProviderURL(url);
      window.open(url);
    }
  };

  return (
    <div className="launcher">
      <h1 className="title">Game Launcher</h1>
      <form onSubmit={isProviderTest ? handleProviderSubmit : handleSubmit}>
        <FormLauncher
          urlForBranch={urlForBranch}
          setUrlForBranch={setUrlForBranch}
          isBranchTest={isBranchTest}
          setBranchTest={setBranchTest}
          isProviderTest={isProviderTest}
          setProviderTest={setProviderTest}
          params={params}
          setParams={setParams}
          slotGamesSorted={slotGamesSorted}
          videoBingoGamesSorted={videoBingoGamesSorted}
          providerInputData={providerInputData}
          setProviderInputData={setProviderInputData}
          operatorUrl={operatorUrl}
          setOperatorUrl={setOperatorUrl}
          ishomeURLValid={ishomeURLValid}
          iscashierURLValid={iscashierURLValid}
          setIshomeURLValid={setIshomeURLValid}
          setIscashierURLValid={setIscashierURLValid}
        />
        <div className="btn-container">
          <button className="btn" type="submit">
            Launch
          </button>
        </div>
        {providerURL && (
          <>
            <div className="url-container">
              <CopyUrl providerURL={providerURL} />
              <h2
                style={{
                  wordBreak: "break-all",
                  color: blue[800],
                  paddingLeft: 10,
                  display: "flex",
                  alignItems: "center",
                  height: "36",
                }}
              >
                Last generated URL:
              </h2>
            </div>
            <p style={{ wordBreak: "break-all", paddingTop: 15 }}>
              {providerURL}
            </p>
          </>
        )}
      </form>
      <HomeButton />
      {isShowModal && (
        <IframeModal
          show={isShowModal}
          onClose={hideModal}
          gameURL={gameURL}
          iFrameReload={configIframe.iFrameReload}
          iFrameCompatibility={iFrameCompatibility}
        />
      )}
    </div>
  );
}
