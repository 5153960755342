import { SelectChangeEvent } from "@mui/material/Select";
import { Providers, Game } from "../../interfaces/interfaces";
import { ENVIRONMENT } from "../../constants";
import { useDataProviders } from "../../hooks/useDataProviders";
import { useState, useEffect } from "react";
import { Provider, StringIndex } from "../../interfaces/interfaces";
import { isDevelopment } from "../../utils";
import React from "react";
import axios from "axios";
import { EQUIVALENT_KEYS } from "../../constants";
import { useLauncherStore } from "../../store/launcher";
import * as ProviderComponents from './index'
import { DEV_PORT4 } from "../../constants";

type Props = {
  providerInputData: StringIndex;
  setProviderInputData: (providerInputData: StringIndex) => void;
  operatorUrl: string;
  setOperatorUrl: (operatorUrl: string) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
  ishomeURLValid: boolean;
  iscashierURLValid: boolean;
  setIshomeURLValid: (ishomeURLValid: boolean) => void;
  setIscashierURLValid: (iscashierURLValid: boolean) => void;
};

export default function ProviderFields({
  providerInputData,
  setProviderInputData,
  operatorUrl,
  setOperatorUrl,
  slotGamesSorted,
  videoBingoGamesSorted,
  ishomeURLValid,
  iscashierURLValid,
  setIshomeURLValid,
  setIscashierURLValid,
}: Props) {
  const { data } = useDataProviders();
  const [provider, setProvider] = useState<Provider>({
    name: "",
    base_urls: [],
  });

  const [environment, setEnvironment] = useState(ENVIRONMENT[0]);
  const [providersByEnv, setProvidersByEnv] = useState<Providers>({
    environment: "",
    providers: [],
  });

  const [providerParams, setProviderParams] = useState<StringIndex>({});
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { gameType, setGameType } = useLauncherStore((state) => state);

  const urlPatternValidation = (URL: string) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  useEffect(() => {
    const route = isDevelopment()
      ? `http://localhost:${DEV_PORT4}/providerParams`
      : `${window.location.protocol}//${window.location.hostname}/providers/${provider.name}/launcher/config.json`;

    const getData = async () => {
      try {
        const response = await axios.get(route);
        setProviderParams(response.data.params);
        setProviderInputData({
          [`${response.data.params.lang}`]: "es",
          [`${response.data.params.game}`]:
            gameType === "slot"
              ? slotGamesSorted[0].name
              : videoBingoGamesSorted[0].name,
          [`${response.data.params.device}`]: "mobile",
          [`${response.data.params.hideFullScreenHand}`]: "true",
          [`${response.data.params.demo}`]: "true",
          [`${response.data.params.homeURL}`]:
            "https://www.google.com/search?q=abc&num=3",
          [`${response.data.params.cashierURL}`]:
            "https://www.google.com/search?q=abc&num=3",
        });
        setErrorMessage("");
      } catch (error) {
        setErrorMessage(`Not data found for the provider: ${provider.name}`);
        setProviderInputData({});
        setProviderParams({});
      }
    };
    if (data && provider?.name) getData();
  }, [
    data,
    provider,
    setProviderInputData,
    gameType,
    slotGamesSorted,
    videoBingoGamesSorted,
  ]);

  useEffect(() => {
    let filtered;
    if (data) {
      filtered = data.filter((element) => element.environment === environment);
      setProvidersByEnv(filtered[0]);
      setProvider(filtered[0].providers[0]);
      setOperatorUrl(filtered[0].providers[0].base_urls[0]);
      setProviderInputData({});
    }
  }, [data, environment, setProviderInputData, setOperatorUrl]);

  const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (EQUIVALENT_KEYS.demo.includes(e.target.name)) {
      setProviderInputData({
        ...providerInputData,
        [e.target.name]: e.target.checked ? "true" : "false",
      });
    } else {
      setProviderInputData({
        ...providerInputData,
        [e.target.name]: (e.target as HTMLInputElement).value,
      });
    }

    if (EQUIVALENT_KEYS.homeURL.includes(e.target.name)) {
      urlPatternValidation(e.target.value) || !e.target.value
        ? setIshomeURLValid(true)
        : setIshomeURLValid(false);
    }

    if (EQUIVALENT_KEYS.cashierURL.includes(e.target.name)) {
      urlPatternValidation(e.target.value) || !e.target.value
        ? setIscashierURLValid(true)
        : setIscashierURLValid(false);
    }
  };

  const handleTypeSelect = (e: SelectChangeEvent<string>) => {
    setProviderInputData({
      ...providerInputData,
      [e.target.name]: (e.target as HTMLInputElement).value,
    });
  };

  const handleGameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(e.target.value);
  };

  const handleChangeGame = (
    value: string,
    name: string
  ): void => {
    setProviderInputData({
      ...providerInputData,
      [name]: value,
    });
  };

  const handleProvider = (value: string): void => {
    if (value) {
      const selectedProvider = providersByEnv.providers.filter(
        (element) => element.name === value
      );
      setProvider(selectedProvider[0]);
      setOperatorUrl(selectedProvider[0].base_urls[0]);
    }
  };

  return (
    <>
      <ProviderComponents.EnvironmentField
        environment={environment}
        setEnvironment={setEnvironment}
        setOperatorUrl={setOperatorUrl}
        setProvider={setProvider}
      />

      <ProviderComponents.ProviderField
        provider={provider}
        providersByEnv={providersByEnv}
        handleProvider={handleProvider}
      />

      <ProviderComponents.OperatorURLField
        provider={provider}
        operatorUrl={operatorUrl}
        setOperatorUrl={setOperatorUrl}
      />

      <ProviderComponents.GameTypeRadio
        gameType={gameType}
        handleGameTypeChange={handleGameTypeChange}
      />

      {!errorMessage &&
        Object.keys(providerParams).length !== 0 &&
        Object.entries(providerParams).map((entrie) => {
          switch (entrie[0]) {
            case "lang":
              return (
                <ProviderComponents.LanguageSelect
                  entrie={entrie}
                  handleTypeSelect={handleTypeSelect}
                  providerInputData={providerInputData}
                  key={entrie[0]}
                />
              );
            case "game":
              return (
                <ProviderComponents.GameAutocomplete
                  entrie={entrie}
                  providerInputData={providerInputData}
                  gameType={gameType}
                  handleChangeGame={handleChangeGame}
                  slotGamesSorted={slotGamesSorted}
                  videoBingoGamesSorted={videoBingoGamesSorted}
                  key={entrie[0]}
                />
              );
            case "device":
              return (
                <ProviderComponents.DeviceRadio
                  entrie={entrie}
                  providerInputData={providerInputData}
                  handleInputsChange={handleInputsChange}
                  key={entrie[0]}
                />
              );
            case "hideFullScreenHand":
              return (
                <ProviderComponents.HideFullScreenHandRadio
                  entrie={entrie}
                  providerInputData={providerInputData}
                  handleInputsChange={handleInputsChange}
                  key={entrie[0]}
                />
              );
            case "demo":
              return (
                <ProviderComponents.DemoCheckbox
                  entrie={entrie}
                  providerInputData={providerInputData}
                  handleInputsChange={handleInputsChange}
                  key={entrie[0]}
                />
              );
            default:
              return (
                <ProviderComponents.DefaultField
                  entrie={entrie}
                  providerInputData={providerInputData}
                  handleInputsChange={handleInputsChange}
                  key={entrie[0]}
                />
              );
          }
        })}
      {!ishomeURLValid && <ProviderComponents.ErrorMessage error={"homeURL is an invalid url"} />}
      {!iscashierURLValid && (
        <ProviderComponents.ErrorMessage error={`cashierURL is invalid url`} />
      )}
      {errorMessage && <ProviderComponents.ErrorMessage error={errorMessage} />}
    </>
  );
}
