import { useLauncherStore } from "../../store/launcher";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { Game, Params } from "../../interfaces/interfaces";
import React from "react";
import * as DefaultComponents from './index'

type Props = {
  setUrlForBranch: (url: string) => void;
  urlForBranch: string;
  isBranchTest: boolean;
  setBranchTest: (isBranchTest: boolean) => void;
  params: Params;
  setParams: (params: Params) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
};

export default function DefaultFields({
  urlForBranch,
  setUrlForBranch,
  isBranchTest,
  setBranchTest,
  params,
  setParams,
  slotGamesSorted,
  videoBingoGamesSorted,
}: Props) {
  const { features } = useConfigProvider();
  const { gameType, setGameType, config, setConfig, iFrameCompatibility } =
    useLauncherStore((state) => state);

  const handleChange = <P extends keyof Params>(prop: P, value: Params[P]) => {
    setParams({ ...params, [prop]: value });
  };

  const handleGameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(e.target.value);
  };

  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: (e.target as HTMLInputElement).checked,
    });
  };

  const handleChangeGame = (
    value: string,
    name: string
  ): void => {
    setParams({ ...params, [name]: value });
  };

  return (
    <>
      {features.withBranch && (
        <DefaultComponents.TestByBranchCheckbox
          isBranchTest={isBranchTest}
          setBranchTest={setBranchTest}
        />
      )}
      {isBranchTest && (
       <DefaultComponents.BranchUrlTextField
          urlForBranch={urlForBranch}
          setUrlForBranch={setUrlForBranch}
        />
      )}
     <DefaultComponents.GameTypeRadio
        gameType={gameType}
        handleGameTypeChange={handleGameTypeChange}
        isBranchTest={isBranchTest}
      />
      {isBranchTest && (
        <DefaultComponents.BranchTextField params={params} handleChange={handleChange} />
      )}
      <DefaultComponents.GameSelect
        params={params}
        isBranchTest={isBranchTest}
        handleChangeGame={handleChangeGame}
        gameType={gameType}
        slotGamesSorted={slotGamesSorted}
        videoBingoGamesSorted={videoBingoGamesSorted}
      />

      <DefaultComponents.DemoCheckbox params={params} handleChange={handleChange} />

      {iFrameCompatibility && (
        <DefaultComponents.IframeModeCheckbox
          handleConfigChange={handleConfigChange}
          config={config}
        />
      )}
      {config.iFrameMode && (
        <DefaultComponents.ReloadOnChangeCheckbox
          handleConfigChange={handleConfigChange}
          config={config}
        />
      )}
      {!config.iFrameMode && iFrameCompatibility && (
        <DefaultComponents.DeviceRadio params={params} handleChange={handleChange} />
      )}
      <DefaultComponents.HideFullScrenHandRadio params={params} handleChange={handleChange} />

      <DefaultComponents.LanguageSelect
        params={params}
        handleChange={handleChange}
      />

      <DefaultComponents.CashierTextField params={params} handleChange={handleChange} />

      <DefaultComponents.HomeURLTextField params={params} handleChange={handleChange} />

      {isBranchTest && (
        <DefaultComponents.ProviderIdTextField params={params} handleChange={handleChange} />
      )}
      {isBranchTest && (
        <DefaultComponents.ProviderOperatorIdField params={params} handleChange={handleChange} />
      )}
    </>
  );
}
