import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { Provider, Providers } from "../../../interfaces/interfaces";

type Props = {
  provider: Provider;
  providersByEnv: Providers;
  handleProvider: (value: string) => void;
};

export function ProviderField({
  provider,
  providersByEnv,
  handleProvider,
}: Props) {
  return (
    <>
      <FormLabel>Provider</FormLabel>
      <Autocomplete
        value={provider?.name === undefined ? "" : provider?.name}
        onChange={(e, value): void => {
          handleProvider( value || "");
        }}
        disablePortal
        options={
          providersByEnv &&
          [...providersByEnv.providers]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((provider) => provider.name)
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: "black",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: "black",
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
            {
              color: "black",
            },
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
