import { create } from "zustand";

type State = {
  lang: string;
};

type Actions = {
  setLang: (lang: string) => void;
};

export const useGameLangStore = create<State & Actions>((set) => ({
  lang: "",
  setLang: (lang: string) =>
    set(() => ({
      lang,
    }))
}));
