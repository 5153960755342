import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function ProviderOperatorIdField({
  params,
  handleChange,
}: Props) {
  return (
    <>
      <FormLabel>Provider OperatorId</FormLabel>
      <TextField
        type="text"
        name="providerOperatorId"
        value={params.providerOperatorId}
        onChange={(e) => {
          handleChange("providerOperatorId", e.target.value);
        }}
        spellCheck="false"
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
        }}
      />
    </>
  );
}
