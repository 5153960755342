import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LANGUAGES } from "../../../constants";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function LanguageSelect({
  handleChange,
  params,
}: Props) {
  return (
    <>
      <FormLabel>Language</FormLabel>
      <Select
        value={params.lang}
        name="lang"
        onChange={(e) => {
          handleChange("lang", e.target.value);
        }}
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        {Object.entries(LANGUAGES).map((lang) => (
          <MenuItem key={lang[0]} value={lang[1]}>
            {lang[0]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
